import React from "react"
import styled from "styled-components"

import Quotation from "../../assets/icons/Quotation.svg"

type CalloutProps = {
  html: string;
}

export const Callout: React.FC<CalloutProps> = props => {
  const { html } = props;
  return <StyledCallout className="body-component callout">
    <BorderBlock />
      <blockquote dangerouslySetInnerHTML={{ __html: html }}/>
    <QuotationContainer>
      <Quotation width="24px" height="18.7px"/>
    </QuotationContainer>
  </StyledCallout>
}

const StyledCallout = styled.div`
  &.body-component.callout {
    position: relative;
    display: flex;
    align-items: start;
    width: -webkit-fill-available;
    margin: 30px auto 56px;
  }
`
  
const QuotationContainer = styled.div`
  padding: 16px 18.65px;
  background: #181818;
  border-radius: 8px;
`

const BorderBlock = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: min(104px, 100%);
  width: min(104px, 100%);
  border-bottom: 1px solid #181818;
  border-left: 1px solid #181818;
`
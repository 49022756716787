import React from 'react'

import { SingleColumn, TwoColumns, ThreeColumns, BodyGroup, StyledSection } from './ui';
import { ContentBody } from './contentBody'
import { RecentArticles } from './recentArticles'
import { useAttributes } from '../hooks'
import { ContentSection } from '../types'
import { getVideoIdFromUrl } from '../util'
import { BackgroundVideo } from './backgroundVideo';
import { RecentPodcasts } from './recentPodcasts';
import { TopArticles } from './topArticles';

type DynamicSectionProps = {
  section: ContentSection;
}

export const DynamicSection: React.FC<DynamicSectionProps> = props => {
  const { section } = props
  const attributes = useAttributes(section.attributes)
  const SectionType = sectionMap[section.type] || StyledSection;
  return <SectionType attributes={attributes} className="dynamic-section" data-testid="dynamic-section">
    { attributes.background_video ? <BackgroundVideo
      videoId={getVideoIdFromUrl(attributes.background_video)}
      height={`${attributes.minimum_height_percentage}vw`}
    /> : null}
    {section.bodies.map((bodyGroup, index) => <BodyGroup key={index} className="body-group">
      {(bodyGroup || []).map(body => body && <ContentBody key={body.data} body={body} />)}
    </BodyGroup>)}
  </SectionType>
}

const sectionMap = {
  "singleColumn": SingleColumn,
  "twoColumns": TwoColumns,
  "threeColumns": ThreeColumns,
  "articleList": RecentArticles,
  "podcastList": RecentPodcasts,
  "topArticles": TopArticles,
  "closingBanner": () => null,
}
import React, { useLayoutEffect, useState, useRef } from 'react';
import styled from 'styled-components'
import { useContainerSize } from '../hooks/useContainerSize';

type BackgroundVideoProps = {
  videoId: string;
  height?: string;
}

export const BackgroundVideo: React.FC<BackgroundVideoProps> = props => {
  const { videoId, height = "415px"} = props;
  const [attempt, setAttempt] = useState(0);
  const mounted = useRef(true);
  const container = useRef(null);
  const { height: containerHeight } = useContainerSize(container)

  useLayoutEffect(() => {
    const cleanup = () => { mounted.current = false; }
    if (!videoId) return cleanup;
    if (typeof window === `undefined` || !window["Wistia"]) {
      // In case react loads and renders before the Wistia script has loaded:
      setTimeout(() => mounted.current && setAttempt(attempt + 1), 200);
      return cleanup;
    }

    window["Wistia"].embed(videoId);

    return cleanup
  }, [videoId, attempt]);

  return <Container
    id={`wistia_${videoId}`}
    className="wistia_embed"
    height={height}
    containerHeight={containerHeight}
    ref={container}
  />
}

const Container = styled.figure`
  position: absolute;
  z-index: -1;
  width: 100vw !important;
  height: ${({ height, containerHeight }) => `max(${containerHeight}px,${height})`} !important;
  left: 0 !important;
  right: 0 !important;
  div {
    max-width: 100vw !important;
    max-height: ${({ height, containerHeight }) => `max(${containerHeight}px,${height})`} !important;
  }
  video {
    object-fit: cover !important;
    object-position: center !important;
    min-height: 100%;
  }
`
import React, { useState } from "react"
import styled, { css } from "styled-components"

import { Button } from "./ui"
import { useOutsideClick } from "../hooks"
import { breakpoint } from "../util"
import Spotify from "../assets/icons/spotify.svg"
import ApplePodcasts from "../assets/icons/apple_podcasts.svg"
import GooglePodcasts from "../assets/icons/google_podcasts.svg"
import Rss from "../assets/icons/rss.svg"
import { useContainerSize } from '../hooks';

type Urls = {
  apple_podcasts_url: string;
  google_podcasts_url: string;
  spotify_url: string;
  stitcher_url: string;
  podchaser_url: string;
  tunein_url: string;
  iheartradio_url: string;
  pandora_url: string;
}

type Attributes = {
  button_color_scheme: string;
  button_color_border: string;
}

type PodcastUrlProps = {
  attributes: Urls & Attributes,
}

const displayNames = {
  apple_podcasts_url: "Apple Podcasts",
  google_podcasts_url: "Google Podcasts",
  spotify_url: "Spotify",
  stitcher_url: "Stitcher",
  podchaser_url: "Podchaser",
  tunein_url: "TuneIn",
  iheartradio_url: "iHeartRadio",
  pandora_url: "Pandora",
}

const icons = {
  spotify_url: Spotify,
  apple_podcasts_url: ApplePodcasts,
  google_podcasts_url: GooglePodcasts,
}

const moreLinks = ["stitcher_url", "podchaser_url", "tunein_url", "iheartradio_url", "pandora_url"]; 

export const PodcastUrls: React.FC<PodcastUrlProps> = props => {
  const { attributes = {} as any } = props;
  const { button_color_scheme, button_color_border } = attributes;

  const [showingMore, setShowingMore] = useState(false);
  const ref = useOutsideClick(() => setShowingMore(false))
  const { width } = useContainerSize(ref);

  return <StyledPodcast className="body-component podcast-" ref={ref} width={width}>
    {["spotify_url", "apple_podcasts_url", "google_podcasts_url"].map(url => {
      if (!attributes[url]) return null;
      const Icon = icons[url];
      return <StyledButton
        as="a"
        href={attributes[url]}
        key={url}
        target="_blank"
        rel="noopener"
        onClick={() => setShowingMore(false)}
        title={displayNames[url]}
        border={button_color_border === "true"}
        variant={button_color_scheme}
        size="small"
      >
        <Icon/>
        <span>{displayNames[url]}</span>
      </StyledButton>
    })}
    <StyledButton
      onClick={() => setShowingMore(!showingMore)}
      variant={button_color_scheme}
      size="small"
      border={button_color_border === "true"}
    >
      <Rss className="margin-right"/>
      More...
    </StyledButton>
    {moreLinks.some(url => attributes[url]) ? <More
      as="div"
      open={showingMore}
      width={width}
      variant={button_color_scheme}
      border={button_color_border === "true"}
    >
      {moreLinks.map(url => attributes[url] && <a href={attributes[url]} key={url} target="_blank" rel="noopener">
          {displayNames[url]}
        </a>)
      }
    </More> : null}
  </StyledPodcast>
}

const wideContainerOnly = (styles) => css`
  ${breakpoint.tablet`
    ${({ width }) => width > 609 ? styles : ''}
  `}
`

const wideStyles = () => css`
  justify-content: flex-start;
  max-width: fit-content;
  a, button {
    span {
      display: inline;
      margin-left: 8px;
    }
  }
`
const StyledPodcast = styled.div`
  position: relative;
  display: flex;
  margin: 0 auto;
  ${wideContainerOnly(wideStyles())}
`

const StyledButton = styled(Button)`
  text-decoration: none;
  margin-right: 16px;
  white-space: nowrap;
  span {
    display: none;
  }
  svg {
    vertical-align: text-top;
    height: 19px;
    &.margin-right {
      margin-right: 8px;
    }
    path {
      fill: ${({ theme, variant }) => ["primary", "tertiary"].includes(variant) ? theme.secondary : theme.primary};
    }
  }
`

const More = styled(StyledButton)`
  position: absolute;
  display: ${({ open }) => open ? 'flex' : 'none'};
  z-index: 1;
  flex-direction: column;
  left: 196px;
  ${wideContainerOnly(`left: 489px;`)}
  top: 36px;

  box-shadow:
    rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px,
    rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  // We need this pseudo element so that the background is never opaque
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: ${({ theme, variant }) => variant === "tertiary" ? theme.primary : variant === "tertiary_inverted" ? theme.secondary : 'inherit'};
    border-radius: 6px;
  }
  a {
    margin-right: 0;
    padding: 0 12px;
    margin: 8px 0;
    color: inherit;
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    line-height: 130%;
    transition: all 0.15s ease-in-out;
    &:hover {
      color: #2683FC;
    }
  }
`
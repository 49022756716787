import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useAttributes } from "../hooks";
import { Attribute } from "../types";
import { prepExternalLink, isInternalLink, font } from "../util";

type ImageProps = {
  url: string;
  attributes: any;
  className?: string;
  loading?: 'lazy' | 'eager';
}

export const Image: React.FC<ImageProps> = props => {
  const { url, attributes, className, loading } = props;
  const { description, caption, link } = attributes;

  const imageProps = { src:url, alt:description, ...(loading && { loading })};

  return <ImageContainer className={`body-component image ${className || ''}`}>
    <ImageWrapper link={link}>
      <img {...imageProps} />
    </ImageWrapper>
    <span>{caption}</span>
  </ImageContainer> 
}

const ImageWrapper: React.FC<{ link?: string }> = props => {
  const { link, children } = props;
  if (!link) return <>{children}</>
  if (isInternalLink(link)) return <Link to={link}>{children}</Link>;
  return <a href={prepExternalLink(link)} target="_blank" rel="noopener">{children}</a>
}

const ImageContainer = styled.div`
  img {
    margin-bottom: 0;
    margin-top: 32px;
    max-height: 100%;
  }
  span {
    ${font.c7}
  }
  margin: auto;
  margin-bottom: 1.45rem;
`

import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Article } from "../types"
import { breakpoint, font, getArticleUrl } from "../util"
import { useAttributes, useLatestArticles, useViewDetector } from "../hooks"
import { Section } from "./ui";
import * as _link from "./ui/link";
import { STATIC_URL } from '../util/urls';
import { CategoryBar } from './categoryBar'

type TopArticlesProps = {
  attributes?: any;
}

export const TopArticles: React.FC<TopArticlesProps> = props => {
  const { attributes = {}, children } = props;
  const [viewClass, detectorRef] = useViewDetector();
  const { data } = useLatestArticles(6);

  const articles = data?.getContents.items || [];
  
  return <TopArticlesSection ref={detectorRef} className={viewClass} attributes={attributes}>
    <Heading>
        Articles <i>& Insights</i>
    </Heading>
    <CategoryBar selectedCategoryId={'none'} />
    <div className="grid">
      {articles.map(article => <ArticleCard 
        article={article} 
        key={article.id} />)}
    </div>
    <div className="children">
      {children}
    </div>
  </TopArticlesSection>
}

const ArticleCard: React.FC<{ article: Partial<Article> }> = props => {
  const { article } = props;
  const { hero_image_description } = useAttributes(article.attributes) as any;

  return <StyledCard to={getArticleUrl(article.slug)} className="article-card">
    <ImageContainer>
      <img loading='lazy' src={article.thumbnail} alt={hero_image_description} />
    </ImageContainer>
    <LabelContainer bordercolor={article.category?.color ?? '#F6E62C'}>
      <h3 className="category">{article.category?.name}</h3>
      <h4 className="title">{article.title}</h4>
    </LabelContainer>
  </StyledCard>
}

const Heading = styled.h2`
  position:relative;
  padding:0 0 1rem;
  margin:0 0 3rem;

  ${font.m6}
  text-align: center;
   
  &::after
  {
    content:"";
    width:280px;
    position:absolute;
    bottom:0;
    left:calc(50% - 140px);
    margin:0 auto;
    border-bottom: 1px solid #b1b1b1;
  }
`

const TopArticlesSection = styled(Section)<{attributes: any}>`
  background-color: ${({ theme, attributes }) => attributes.background_color || theme.bg};
  
  header
  {
    display: none;
    
    ${breakpoint.laptop`
      display:flex;
      flex-flow:row nowrap;
      justify-content:center;
      list-style:none;
      margin:3rem 0;

      a {
        margin-bottom:0;
      }
    `}
  }

  .grid {
    margin: 22px 0 36px;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 32px;

    // only show 3 articles on mobile
    > .article-card:nth-child(n + 4)
    {
      display:none;
    }

    ${breakpoint.tablet`
      grid-template-columns: repeat(2, 1fr);

      // only show 4 articles on tablet
      > .article-card:nth-child(n)
      {
        display:block;
      }

      // only show 3 articles on mobile
      > .article-card:nth-child(n + 5)
      {
        display:none;
      }
    `}

    ${breakpoint.laptop`
      grid-template-columns: repeat(3, 1fr);

      // show all articles
      > .article-card:nth-child(n)
      {
        display:block;
      }
    `}
    


    
    .article-card {
      transition: all 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
      transition-delay: 0.2s;
      &:nth-child(3n + 1) {
        transition-delay: 0.25s;
      }
      &:nth-child(3n + 2) {
        transition-delay: 0.45s;
      }
      &:nth-child(3n + 3) {
        transition-delay: 0.65s;
      }
      &:nth-child(3n + 4) {
        transition-delay: 0.85s;
      }
      &:nth-child(3n + 5) {
        transition-delay: 1.05s;
      }
      &:nth-child(3n + 6) {
        transition-delay: 1.25s;
      }
    }
  }

  &:not(.in-view) {
    .article-card  {
      opacity: 0;
      transform: translate(0px, 500px);
    }
  }

  
  .children {
    text-align: center;
    margin-bottom: 48px;
    ${breakpoint.tablet`
      margin-bottom: 96px;
    `}
  }
`


const StyledCard = styled(Link)`
  min-height:200px; /* reduce bounce */  
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`


const ImageContainer = styled.div`
  width: 100%;
  border:1px solid rgba(0,0,0,.1);
  border-radius:10px;
  overflow:hidden;

  img {
    display:block;
    width:100%;
    max-height:186px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 0;
  }
`

const LabelContainer = styled.div`
  position:relative;
  padding: .5rem 1rem;
  border-bottom:8px solid ${({ bordercolor }) => bordercolor || '#FF6539'};
  border-radius:10px;
  background:#fff;
  margin:-2rem 1rem 0;
  min-height:94px;
  

  .category {
    ${font.c7}
    font-size:10px !important;
    text-transform: uppercase;
    color: #666666;

    padding:0;
    margin: 0;
  }
  .title {
    ${font.c2}
    font-size:18px !important;
    color: #181818;
    margin:4px 0 0;
  }
`
import React from 'react'
import styled from 'styled-components'
import { DynamicButton } from './ui'
import { useDispatch } from "react-redux"
import { openModal } from "../state/actions"

type VideoPlayerButtonProps = {
  text: string;
  attributes: any;
  className?: string;
}

export const VideoPlayerButton: React.FC<VideoPlayerButtonProps> = props => {
  const { text, attributes, className = ""} = props;
  const dispatch = useDispatch()

  return <VideoButton
    attributes={attributes}
    className={`body-component video-player-button ${className}`}
    onClick={() => dispatch(openModal("VideoPlayerModal", attributes))}
  >
    {text}
  </VideoButton>
}

const VideoButton = styled(DynamicButton)`
  margin: 0 auto;
`
import React from 'react'
import styled from 'styled-components'
import { ContentBody as ContentBodyType } from '../types'
import { SignUpButton } from './signUpButton'
import { VideoPlayerButton } from './videoPlayerButton'
import { Callout } from './ui/callout';
import { Image } from './image';
import { useAttributes } from '../hooks';
import { PodcastUrls } from './podcastUrls'

type ContentBodyProps = {
  body: ContentBodyType;
}

export const ContentBody: React.FC<ContentBodyProps> = props => {
  const { body } = props;
  const attributes = useAttributes(body.attributes);
  const Component = bodyMap[body.type] || bodyMap.html;
  return <Component body={body} attributes={attributes}/>
}

const bodyMap = {
  "text": ({ body, attributes }) => <TextBody
    data-testid="body"
    attributes={attributes}
    className="body-component text"
  >{body.data}</TextBody>,
  "html": ({ body, attributes }) => <TextBody
    data-testid="body"
    attributes={attributes}
    className="body-component html"
    dangerouslySetInnerHTML={{ __html: body.data }}
  />,
  "waitlistEmailCaptureForm": ({ body, attributes }) => <SignUpButton attributes={attributes} text={body.data} />,
  "videoPlayerButton": ({ body, attributes }) => <VideoPlayerButton text={body.data} attributes={attributes} />,
  "callout": ({ body }) => <Callout html={body.data}/>,
  "image": ({ body, attributes }) => <Image url={body.data} attributes={attributes} />,
  "podcastUrls": ({ attributes }) => <PodcastUrls attributes={attributes} />,
};

const TextBody = styled.div`
  text-align: ${({ attributes }) => attributes.text_alignment || 'left'};
  ${({ attributes }) => attributes.text_color ? `color: ${attributes.text_color};` : ''}
`
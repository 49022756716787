import React, { useState } from "react"
import styled from "styled-components"
import dayjs from 'dayjs';
import { Link } from "gatsby"

import { Podcast } from "../types"
import { Button, Section } from "./ui"
import { breakpoint, getPodcastUrl, font } from "../util"
import { useAttributes, useLatestArticles, useScrollToBottom } from "../hooks"
import PlayIcon from "../assets/icons/ico-Play.svg"

type RecentPodcastsProps = {
  limit?: number;
  showSearchBar?: boolean;
  attributes?: any;
}

export const RecentPodcasts: React.FC<RecentPodcastsProps> = props => {
  const { limit = 9, showSearchBar = true } = props;
  const [nextToken, setNextToken] = useState<string>();
  const [items, setItems] = useState([]);

  const { data: newData, loading } = useLatestArticles(limit, nextToken, "", "podcast");
  const podcasts = loading ? items : Array.from(new Set(items.concat(newData.getContents.items)))

  const containerRef = useScrollToBottom(() => {
    if (loading || !newData.getContents.nextToken) return;
    setItems(podcasts)
    setNextToken(newData.getContents.nextToken);
  });

  return <Section>
    {showSearchBar ? <SearchBar><h3>Most recent episodes</h3></SearchBar> : null}
    <RecentPodcastsList ref={containerRef} data-testid="recent-podcasts">
        {podcasts.map(podcast => <PodcastCard podcast={podcast} key={podcast.id}/>)}
        {loading ? "Loading..." : null}
    </RecentPodcastsList>
</Section>
}

const PodcastCard: React.FC<{ podcast: Partial<Podcast> }> = props => {
  const { podcast } = props;
  const { hero_image_description } = useAttributes(podcast.attributes) as any;
  return <StyledCard to={getPodcastUrl(podcast.slug)}>
    <ImageContainer>
      <img src={podcast.thumbnail} alt={hero_image_description}/>
      <PlayButton>
          <PlayIcon aria-label="play icon"/>
          Play Episode
      </PlayButton>
    </ImageContainer>
    <LabelContainer>
      <EpisodeLabel>
        <span>{dayjs(new Date(podcast.publishDate)).format('MM/DD/YYYY')}</span>
      </EpisodeLabel>
      <h4 className="title">{podcast.title}</h4>
      <div className="sub-title" dangerouslySetInnerHTML={{ __html: podcast.subtitle }}/>
    </LabelContainer>
  </StyledCard>
}

const RecentPodcastsList = styled.div`
  padding: 40px 0;
  ${breakpoint.desktop`padding: 40px 0;`}
  display: flex;
  flex-direction: column;
  ${breakpoint.tablet`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px;
  `}
`

const SearchBar = styled.div`
  border-bottom: 1px solid #C4C4C4;
  margin: 0;
  ${breakpoint.desktop`margin: 0;`}
  h3 {
    ${font.c6}
    text-transform: uppercase;
    margin-top: 26px;
  }
`

const StyledCard = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: auto;
  height: 189px;
  img {
    height: 100%;
    min-width: 100%;
    object-fit: cover;
    margin-bottom: 0;
  }
`

const PlayButton = styled(Button)`
  position: absolute;
  background-color: ${({ theme }) => theme.bg};
  border-radius: 6px;
  ${font.c7}
  line-height: 1;
  padding: 12px;
  bottom: 16px;
  left: 16px;
  svg {
    margin-right: 12px;
    position: relative;
    top: 1px;
  }
`

const LabelContainer = styled.div`
  h4.title {
    margin-bottom: 8px;
    ${font.m4}
    color: ${({ theme }) => theme.primary};
  }
  div.sub-title {
    margin-bottom: 8px;
    color: ${({ theme }) => theme.primary};
    p {
      ${font.c6}
    }
  }
`

const EpisodeLabel = styled.div`
  ${font.c7}
  margin: 10px 0;
  color: #666666;
  display: flex;
  justify-content: space-between;
`